<template>
  <div class="orderDeliveryDetail">
    <div class="zone">
      <div class="title">订单发货</div>
      <div class="Btn">
        <el-button type="primary" @click="saveLocal">保存到缓存</el-button>
        <el-button type="success" @click="localSetOld">获取缓存</el-button>
        <el-button type="info" @click="changeTB('change')">当前扫码{{Is_TB?'普通TB':'婚检TB'}}</el-button>

      </div>
      <!-- 发货信息 -->
      <div class="card cardA">
        <p class="p1">
          <span>
            <img :src="cardA.img" alt="" />
            订单编号:{{ cardA.title }}
          </span>
          <span :class="cardA.status == '已发货' ? 'green' : ''">
            <i :class="cardA.status == '已发货' ? 'greenBg' : ''"></i>
            {{ cardA.status }}
          </span>
        </p>
        <ul>
          <li v-for="(item, idnex) of cardA.list" :key="idnex">
            <p>{{ item.name }}：</p>
            <p>{{ item.value }}</p>
          </li>
        </ul>
      </div>

      <!-- 快递信息 -->
      <div class="card cardB">
        <p class="p2">
          <img :src="cardB.img" alt="" />
          <span>快递类型：</span>
          <span>
            <el-select
              v-model="cardB.shipping_type"
              placeholder="请选择快递内容"
            >
              <el-option
                v-for="item in shippingTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </span>
          <span>快递单号：</span>
          <span>
            <el-input
              v-model="cardB.shipping_code"
              placeholder="请输入快递单号"
            ></el-input>
          </span>
        </p>
        <ul class="u1">
          <li>
            <p>收货人</p>
            <p>
              <el-input
                v-model="cardB.receive_man"
                placeholder="请输入收货人"
              ></el-input>
            </p>
          </li>
          <li>
            <p>收货地址</p>
            <p>
              <el-input
                v-model="cardB.receive_address"
                placeholder="请输入收货地址"
              ></el-input>
            </p>
          </li>
        </ul>
        <ul class="u1">
          <li>
            <p>电话</p>
            <p>
              <el-input
                v-model="cardB.receive_mobile"
                placeholder="请输入电话"
              ></el-input>
            </p>
          </li>
          <li>
            <p>备注</p>
            <p>
              <el-input
                v-model="cardB.memo"
                placeholder="请输入备注内容(没有备注请填写无)"
              ></el-input>
            </p>
          </li>
        </ul>
      </div>

      <!-- 商品出库明细 -->
      <p class="titleGoods">商品出库明细</p>
      <div class="goodsContent">
        <div class="boxA">
          <p>
            <span>{{ quantity_cnt }}</span>
            <span>订单数量总计(盒)</span>
          </p>
          <p
            :style="
              'background:' + (detailData.isError == 1 ? '#FF3370' : '#70B458')
            "
          >
            <span>{{ scansNum }}</span>
            <span>扫描数量总计(盒)</span>
          </p>
        </div>
        <div class="rightBox">
          <div
            :class="
              showSeniorSearchFlag == true
                ? 'activeOpen list'
                : 'activeClose list'
            "
          >
            <div class="boxB" v-for="(item, index) of cardC" :key="index">
              <span class="span1">
                {{ index + 1 > 9 ? index + 1 : '0' + (index + 1) }}
              </span>
              <span class="span2" v-show="item.status == 2">√</span>
              <p class="titleBox">
                <span>{{ item.name }}</span>
                <span>批号：{{ item.value }}</span>
              </p>
              <div class="twoBox">
                <p>
                  <span>{{ item.a }}</span>
                  <!-- <span>订单数量(盒)</span> -->
                  <span>
                    {{ item.is_udi ? '订单数量(盒)' : '订单数量(人份数)' }}
                  </span>
                </p>
                <span class="line"></span>
                <!-- {{item.is_udi}} -->
                <p v-if="item.is_udi">
                  <span
                    :style="
                      'color:' +
                      (item.status == 0
                        ? '#999999'
                        : item.status == 1
                        ? '#FF3370'
                        : '#54B64A')
                    "
                  >
                    {{ item.b }}
                  </span>
                  <span>扫描数量(盒)</span>
                </p>
                <div v-else class="inputNum">
                  <el-input
                    v-model="item.b"
                    placeholder="请输入数量"
                    type="number"
                  ></el-input>
                  <!-- <span>(盒)</span> -->
                  <span>{{ item.is_udi ? '(盒)' : '(人份数)' }}</span>
                </div>
              </div>
            </div>
          </div>

          <p class="openBtn">
            <span @click="showSeniorSearchFlag = !showSeniorSearchFlag">
              {{ showSeniorSearchFlag == false ? '收起' : '展开' }}
              <i
                :class="
                  showSeniorSearchFlag == false
                    ? 'activeIcon el-icon-caret-bottom'
                    : 'activeDow el-icon-caret-bottom'
                "
              ></i>
            </span>
          </p>
        </div>
      </div>

      <el-table
        :data="tableData"
        height="450"
        :row-class-name="tableRowClassName"
      >
        <el-table-column
          type="index"
          label="序号"
          height="30"
          align="center"
          width="100"
        ></el-table-column>
        <el-table-column
          prop=""
          label="UDI"
          height="30"
          width="500px"
          align="center"
        >
          <template slot-scope="scope">
            <div class="bbb">
              <el-input
                :id="'tableItem' + scope.row.index"
                v-model="scope.row.udi"
                placeholder="请扫描UDI码"
                :disabled="scope.row.isDisabled"
                @change="changeInput(scope.row)"
              ></el-input>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="productName"
          label="产品名"
          height="30"
          align="center"
        ></el-table-column>
        <el-table-column fixed="right" label="操作" width="120">
          <template slot-scope="scope">
            <el-button
              @click.native.prevent="deleteRow(scope.$index, tableData)"
              type="text"
              size="small"
            >
              移除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="el_pagination_style">
        <el-pagination
          :hide-on-single-page="total <= 0"
          background
          layout=" prev, pager, next, jumper"
          :total="total"
          :current-page.sync="page"
          @current-change="handleCurrentChange"
        />
      </div>
      <!-- 声音 -->
      <audio class="success" id="#audio" src="@/assets/mp3/error.mp3"></audio>
      <audio
        class="success"
        id="success"
        src="@/assets/mp3/success.mp3"
      ></audio>
    </div>
    <div class="bottomBtn">
      <el-button type="info" @click="cancel">取消</el-button>
      <el-button type="primary" @click="confirmFun">确认</el-button>
      <el-popconfirm
        title="确认此单据强制发货吗"
        @confirm="transfer_single_dispatchFun"
      >
        <el-button slot="reference" type="danger" >强制发货</el-button>
      </el-popconfirm>

    </div>
    <!-- 校验确认框 -->
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
      <span>{{ toolTip }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="
            create_kitFun()
            dialogVisible = false
          "
        >
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import api from '@/api/other.js'
import { Message } from 'element-ui'
import { isEmptyObject } from '@/assets/js/tool.js'
import apiDetail from '../../../api/searchDetail'
/**
 * 数据缓存原理
 * 1、每增加十个数据切换页面的时候保存到old里面，且缓存到本地
 * 2、点开页面，刷新页面，主动获取缓存的时候 走逻辑，将缓存填到old，同时判断数量，修改total和page的值
 * 3、主动填写缓存则将old保存到缓存
 * 4、不调整tableData的值和newtable的值,修改前页数据不会自动保存到缓存里面
 */
export default {
  created() {
    let query = this.$route.query
    this.dispatch_id = query.dispatch_id
    this.order_dispatch_detailsFun()
    this.changeTB('init') //普通扫码，初始化udilist
  },
  mounted() {
    this.audio = document.getElementById('#audio')
    this.success = document.getElementById('success')
    this.tableData = JSON.parse(JSON.stringify(this.temptableData));
    this.newtableData = JSON.parse(JSON.stringify(this.temptableData));
    this.localSetOld();
  },
  data() {
    return {
      input: '',
      quantity_cnt: 0,
      total: 10,
      page: 1,
      audio: '',
      success: '',
      dialogVisible: false,
      Is_TB: true,
      dispatch_id: '',
      toolTip: '',
      detailData: {
        isError: 1,
      },
      shippingTypeList: [
        {
          value: '1',
          label: '美乐维',
        },
        {
          value: '2',
          label: '顺丰速运',
        },
        {
          value: '3',
          label: '中铁快运',
        },
        {
          value: '4',
          label: 'EMS',
        },
        {
          value: '5',
          label: '华空物流',
        },
        {
          value: '6',
          label: '自提',
        },
      ],
      cardA: {
        title: 'DDBH2982498478',
        status: '已发货',
        customer_id: '',
        img: require('@/assets/img/shipment.png'),
        list: [
          {
            name: '发货单号',
            value: '',
          },
          {
            name: '出库单号',
            value: '',
          },
          {
            name: '经销商',
            value: '',
          },
          {
            name: '创建时间',
            value: '',
          },
        ],
      },
      cardB: {
        shipping_code: '',
        shipping_type: '',
        receive_man: '',
        receive_mobile: '',
        receive_address: '',
        memo: '无',
      },
      cardC: [], //发货种类

      showSeniorSearchFlag: true,
      tableDataItem: {
        index: 1,
        udi: '',
        productName: '',
        batch: '',
        isDisabled: false,
        status: 1,
        dispatchs_id: '',
      },

      nowTableIndex: 1,
      tableData: [
        {
          index: 1,
          udi: '',
          productName: '',
          isDisabled: false,
          batch: '',
          status: 1,
          dispatchs_id: '',
        },
      ],
      oldtableData: [],//前数据存储
      newtableData: [],//最新页面数据暂存储
      temptableData: [
        {
          index: 1,
          udi: '',
          productName: '',
          isDisabled: false,
          batch: '',
          status: 1,
          dispatchs_id: '',
        },
        {
          index: 2,
          udi: '',
          productName: '',
          isDisabled: false,
          batch: '',
          status: 1,
          dispatchs_id: '',
        },
        {
          index: 3,
          udi: '',
          productName: '',
          isDisabled: false,
          batch: '',
          status: 1,
          dispatchs_id: '',
        },
        {
          index: 4,
          udi: '',
          productName: '',
          isDisabled: false,
          batch: '',
          status: 1,
          dispatchs_id: '',
        },
        {
          index: 5,
          udi: '',
          productName: '',
          isDisabled: false,
          batch: '',
          status: 1,
          dispatchs_id: '',
        },
        {
          index: 6,
          udi: '',
          productName: '',
          isDisabled: false,
          batch: '',
          status: 7,
          dispatchs_id: '',
        },
        {
          index: 7,
          udi: '',
          productName: '',
          isDisabled: false,
          batch: '',
          status: 1,
          dispatchs_id: '',
        },
        {
          index: 8,
          udi: '',
          productName: '',
          isDisabled: false,
          batch: '',
          status: 1,
          dispatchs_id: '',
        },
        {
          index: 9,
          udi: '',
          productName: '',
          isDisabled: false,
          batch: '',
          status: 1,
          dispatchs_id: '',
        },
        {
          index: 10,
          udi: '',
          productName: '',
          isDisabled: false,
          batch: '',
          status: 1,
          dispatchs_id: '',
        },

      ],
      noUdi: ['31070100101','31070100102','31070100103'],//产品//不使用udi的物品
      inputWidth: '300px',
      UDIList: [
        {
          rfid: '0401401',
          di: '06974718028820',
          inventory_id: '31020101507',
          name: 'O-CRP(100)瓶装全组份(mini+、Ottoman-1000、Ottoman-2020)',
        },
        {
          rfid: '0400501',
          di: '06974718028813',
          inventory_id: '31020101502',
          name: 'O-CRP(100)瓶装单试剂(mini+、Ottoman-1000、Ottoman-2020)',
        },
        {
          rfid: '0401406',
          di: '06974718028837',
          inventory_id: '31020101508',
          name: 'O-CRP(200)瓶装全组份(Ottoman)',
        },
        {
          rfid: '0400506',
          di: '06974718028844',
          inventory_id: '31020101501',
          name: 'O-CRP(200)瓶装单试剂(Ottoman)',
        },
        {
          rfid: '0401413',
          di: '06974718028851',
          inventory_id: '31020101512',
          name: 'O-CRP(200)瓶装全组份(Ottoman-2020)',
        },
        {
          rfid: '0400513',
          di: '06974718028868',
          inventory_id: '31020101511',
          name: 'O-CRP(200)瓶装单试剂(Ottoman-2020)',
        },
        {
          rfid: '0401407',
          di: '06974718028875',
          inventory_id: '31020101509',
          name: 'O-CRP(240)瓶装全组份(Ottoman)',
        },
        {
          rfid: '0400507',
          di: '06974718028882',
          inventory_id: '31020101302',
          name: 'O-CRP(240)瓶装单试剂(Ottoman)',
        },
        {
          rfid: '0400701',
          di: '06974718028608',
          inventory_id: '31020101607',
          name: 'O-SAA(100)瓶装全组分(mini+、Ottoman-1000、Ottoman-2020)',
        },
        {
          rfid: '0400601',
          di: '06974718028615',
          inventory_id: '31020101605',
          name: 'O-SAA(100)瓶装单试剂(mini+、Ottoman-1000、Ottoman-2020)',
        },
        {
          rfid: '0400706',
          di: '06974718028622',
          inventory_id: '31020101608',
          name: 'O-SAA(200)瓶装全组分(Ottoman)',
        },
        {
          rfid: '0400606',
          di: '06974718028639',
          inventory_id: '31020101606',
          name: 'O-SAA(200)瓶装单试剂(Ottoman)',
        },
        {
          rfid: '0400713',
          di: '06974718028646',
          inventory_id: '31020101617',
          name: 'O-SAA(200)瓶装全组分(Ottoman-2020)',
        },
        {
          rfid: '0400613',
          di: '06974718028653',
          inventory_id: '31020101616',
          name: 'O-SAA(200)瓶装单试剂(Ottoman-2020)',
        },

        {
          rfid: '0401000',
          di: '06974718028158',
          inventory_id: '31060102501',
          name: 'SAA质控品',
        },
        {
          rfid: '0400900',
          di: '06974718028165',
          inventory_id: '31060102401',
          name: 'SAA校准品',
        },

        {
          rfid: '0400801',
          di: '06974718028714',
          inventory_id: '31020101602',
          name: 'O-HbA1c(100)瓶装单试剂(mini+、Ottoman-1000、Ottoman-2020)',
        },
        {
          rfid: '0401601',
          di: '06974718028721',
          inventory_id: '31020101810',
          name: 'O-PCT(100)瓶装全组分(mini+、Ottoman-1000、Ottoman-2020)',
        },
        {
          rfid: '0401501',
          di: '06974718028738',
          inventory_id: '31020101809',
          name: 'O-PCT(100)瓶装单试剂(mini+、Ottoman-1000、Ottoman-2020)',
        },
        {
          rfid: '0320102',
          di: '06974718026000',
          inventory_id: '31040100101',
          name: 'M-proBNP(20)',
        },
        {
          rfid: '0320202',
          di: '06974718026017',
          inventory_id: '31040100201',
          name: 'M-PCT(20)',
        },
        {
          rfid: '0320204',
          di: '06974718026024',
          inventory_id: '31040100202',
          name: 'M-PCT(50)',
        },
        {
          rfid: '0320402',
          di: '06974718026031',
          inventory_id: '31040102301',
          name: 'M-CKMB(20)',
        },
        {
          rfid: '0320302',
          di: '06974718026048',
          inventory_id: '31040102201',
          name: 'M-cTnI(20)',
        },
        {
          rfid: '0320502',
          di: '06974718026055',
          inventory_id: '31040100301',
          name: 'M-Myo(20)',
        },
        {
          rfid: '0320802',
          di: '06974718026062',
          inventory_id: '31040100601',
          name: 'M-DD(20)',
        },
        {
          rfid: '0320702',
          di: '06974718026079',
          inventory_id: '31040102401',
          name: 'M-HFABP(20)',
        },
        {
          rfid: '0320602',
          di: '06974718026086',
          inventory_id: '31040102501',
          name: 'M-Cardiac 3in1(20)',
        },
        {
          rfid: '0320902',
          di: '06974718026093',
          inventory_id: '31040100701',
          name: 'M-SAA(20)',
        },
        {
          rfid: '0321302',
          di: '06974718026109',
          inventory_id: '31040102601',
          name: 'M-CS(20)',
        },
        {
          rfid: '0321402',
          di: '06974718026116',
          inventory_id: '31040102701',
          name: 'M-ST2(20)',
        },
        {
          rfid: '0210802',
          di: '06974718026123',
          inventory_id: '31010100801',
          name: 'A-SPOT(20)',
        },
        {
          rfid: '0210602',
          di: '06974718026130',
          inventory_id: '31010100701',
          name: 'C-SPOT(20)',
        },
        {
          rfid: '0210606',
          di: '06974718026147',
          inventory_id: '31010100702',
          name: 'C-SPOT(200)',
        },
        {
          rfid: '0210505',
          di: '06974718026154',
          inventory_id: '31010100703',
          name: 'C-192(192)',
        },
        {
          rfid: '0211002',
          di: '06974718026161',
          inventory_id: '31010101101',
          name: 'S-SPOT(20)（冻干）',
        },
        {
          rfid: '0211102',
          di: '06974718026178',
          inventory_id: '31010101104',
          name: 'S-SPOT(20)（液体）',
        },
        {
          rfid: '0211106',
          di: '06974718026185',
          inventory_id: '31010101103',
          name: 'S-SPOT(200)（液体）',
        },
        {
          rfid: '0210702',
          di: '06974718026192',
          inventory_id: '31010100901',
          name: 'D-SPOT(20)',
        },
        {
          rfid: '0210903',
          di: '06974718026208',
          inventory_id: '31010101001',
          name: 'H-SPOT(40)（胶体金法）',
        },
  
        {
          rfid: '0402113',
          di: '06974718028110',
          inventory_id: '31020101522',
          name: 'A-CRP1瓶装单试剂(2*100)',
        },

        {
          rfid: '0321502',
          di: '06974718026239',
          inventory_id: '31040102901',
          name: 'D-PCT(20)',
        },

        {
          rfid: '0606501',
          di: '06974718028301',
          inventory_id: '31020101703',
          name: 'O-mALB(100)瓶装单试剂(mini+)',
        },
        {
          rfid: '1',
          di: '06974718028318',
          inventory_id: '31060100701',
          name: 'mALB/CRE复合质控',
        },
        {
          rfid: '0606601',
          di: '06974718028479',
          inventory_id: '31020101704',
          name: 'O-mALB(100)瓶装全组份(mini+)',
        },

        {
          rfid: '0402400',
          di: '06974718028127',
          inventory_id: '31060101602',
          name: 'A-CRP1质控品',
        },
        {
          rfid: '0402300',
          di: '06974718028134',
          inventory_id: '31060101601',
          name: 'A-CRP1校准品',
        },
        {
          rfid: '0402213',
          di: '06974718028103',
          inventory_id: '31020101523',
          name: 'A-CRP1瓶装全组份(2*100)',
        },
        {
          rfid: '0700104',
          di: '06974718020008',
          inventory_id: '31080100101',
          name: 'C-PCT全组分(50)',
        },
        {
          rfid: '0700200',
          di: '06974718020015',
          inventory_id: '31060101701',
          name: 'C-PCT全组份校准品',
        },
        {
          rfid: '0700300',
          di: '06974718020022',
          inventory_id: '31060101702',
          name: 'C-PCT全组份质控品',
        },
        {
          rfid: '0402800',
          di: '1',
          inventory_id: '31060101502',
          name: 'A-SAA质控品',
        },
        {
          rfid: '0402700',
          di: '1',
          inventory_id: '31060101501',
          name: 'A-SAA校准品',
        },
        {
          rfid: '1',
          di: '06974718028189',
          inventory_id: '31020101622',
          name: 'A-SAA(200)瓶装全组分（Ottoman-2020)',
        },
        {
          rfid: '1',
          di: '06974718028172',
          inventory_id: '31020101621',
          name: 'A-SAA(100)试剂',
        },
        {
          rfid: '0700500',
          di: '06974718020046',
          inventory_id: '31060102101',
          name: 'C-NT-proBNP全组份校准品',
        },
        {
          rfid: '0700600',
          di: '06974718020053',
          inventory_id: '31060102102',
          name: 'C-NT-proBNP全组份质控品',
        },
        {
          rfid: '0700404',
          di: '06974718020039',
          inventory_id: '31080100501',
          name: 'C-NT-proBNP（50）瓶装全组份试剂',
        },
        {
          rfid: '0700800',
          di: '06974718020077',
          inventory_id: '31060102001',
          name: 'C-MYO全组份校准品',
        },
        {
          rfid: '0700900',
          di: '06974718020084',
          inventory_id: '31060102002',
          name: 'C-MYO全组份质控品',
        },
        {
          rfid: '0700704',
          di: '06974718020060',
          inventory_id: '31080100401',
          name: 'C-MYO（50）瓶装全组份试剂',
        },
        {
          rfid: '0701100',
          di: '06974718020107',
          inventory_id: '31060101901',
          name: 'C-cTnT全组份校准品',
        },
        {
          rfid: '0701200',
          di: '06974718020114',
          inventory_id: '31060101902',
          name: 'C-cTnT全组份质控品',
        },
        {
          rfid: '0701004',
          di: '06974718020091',
          inventory_id: '31080100301',
          name: 'C-cTnT（50）瓶装全组份试剂',
        },
        {
          rfid: '0701400',
          di: '06974718020138',
          inventory_id: '31060101801',
          name: 'C-CKMB全组份校准品',
        },
        {
          rfid: '0701500',
          di: '06974718020145',
          inventory_id: '31060101802',
          name: 'C-CKMB全组份质控品',
        },
        {
          rfid: '0701304',
          di: '06974718020121',
          inventory_id: '31080100201',
          name: 'C-CKMB（50）瓶装全组份试剂',
        },


        {
          rfid: '0607001',
          di: '06974718028325',
          inventory_id: '31020110201',
          name: 'O-NAG(100)瓶装单试剂（mini+)',
        },
        {
          rfid: '0607100',
          di: '06974718028332',
          inventory_id: '31060102301',
          name: 'O-NAG全组份校准品（mini+)',
        },
        {
          rfid: '0607200',
          di: '06974718028349',
          inventory_id: '31060102302',
          name: 'O-NAG全组份质控品（mini+)',
        },
        {
          rfid: '0607301',
          di: '06974718028356',
          inventory_id: '31020110301',
          name: 'O-A1M(100)瓶装单试剂（mini+)',
        },
        {
          rfid: '0607400',
          di: '06974718028363',
          inventory_id: '31060102701',
          name: 'O-A1M全组份校准品（mini+)',
        },
        {
          rfid: '0607500',
          di: '06974718028370',
          inventory_id: '31060102702',
          name: 'O-A1M全组份质控品（mini+)',
        },
        {
          rfid: '0607601',
          di: '06974718028387',
          inventory_id: '31020110601',
          name: 'O-uTRF(100)瓶装单试剂（mini+)',
        },
        {
          rfid: '0607700',
          di: '06974718028394',
          inventory_id: '31060103001',
          name: 'O-uTRF全组份校准品（mini+)',
        },
        {
          rfid: '0607800',
          di: '06974718028400',
          inventory_id: '31060103002',
          name: 'O-uTRF全组份质控品（mini+)',
        },
        {
          rfid: '0607901',
          di: '06974718028417',
          inventory_id: '31020110401',
          name: 'O-B2M (100)瓶装单试剂（mini+)',
        },
        {
          rfid: '0608000',
          di: '06974718028424',
          inventory_id: '31060102801',
          name: 'O-B2M全组份校准品（mini+)',
        },
        {
          rfid: '0608100',
          di: '06974718028431',
          inventory_id: '31060102802',
          name: 'O-B2M全组份质控品（mini+)',
        },
        {
          rfid: '0608201',
          di: '06974718028448',
          inventory_id: '31020110501',
          name: 'O-RBP(100)瓶装单试剂（mini+)',
        },
        {
          rfid: '0608300',
          di: '06974718028455',
          inventory_id: '31060102901',
          name: 'O-RBP全组份校准品（mini+)',
        },
        {
          rfid: '0608400',
          di: '06974718028462',
          inventory_id: '31060102902',
          name: 'O-RBP全组份质控品（mini+)',
        },
        {
          rfid: '0402901',
          di: '06974718028745',
          inventory_id: '31020101901',
          name: 'O-DD(100)瓶装全组份试剂（mini+、Ottoman-1000、Ottoman-2020)',
        },
        {
          rfid: '0403000',
          di: '06974718028752',
          inventory_id: '31060102201',
          name: 'O-DD校准品',
        },
        {
          rfid: '0403100',
          di: '06974718028769',
          inventory_id: '31060102202',
          name: 'O-DD质控品',
        },


        {
          rfid: '0210302',
          di: '06974718026611',
          inventory_id: '31010100102',
          name: 'TB-DOT II(20)（胶体金法）',
        },
        // {
        //   rfid: '0210202',
        //   di: '06974718026604',
        //   inventory_id: '31010100101',
        //   name: 'TB-DOT(20)',
        // },
        {
          rfid: '0607604',
          di: '06974718028516',
          inventory_id: '31020110602',
          name: 'O-CRP2(50)瓶装单试剂（mini+、Ottoman-1000、Ottoman-2020)',
        },
        {
          rfid: '1',
          di: '06974718028899',
          inventory_id: '31020101517',
          name: 'O-CRP2(100)瓶装单试剂（mini+、Ottoman-1000、Ottoman-2020)',
        },
        {
          rfid: '1',
          di: '6974718020190',
          inventory_id: '31060101701',
          name: 'C-PCT全组份校准品',
        },
        {
          rfid: '1',
          di: '06974718020206',
          inventory_id: '31060101702',
          name: 'C-PCT全组份质控品',
        },
        {
          rfid: '1',
          di: '06974718020213',
          inventory_id: '31060102101',
          name: 'C-NT-proBNP全组份校准品',
        },
        {
          rfid: '1',
          di: '06974718020220',
          inventory_id: '31060102102',
          name: 'C-NT-proBNP全组份质控品',
        },
        {
          rfid: '1',
          di: '06974718028929',
          inventory_id: '31060101601',
          name: 'A-CRP1全组份校准品',
        },
        {
          rfid: '1',
          di: '06974718028936',
          inventory_id: '31060101602',
          name: 'A-CRP1全组份质控品',
        },
        {
          rfid: '1',
          di: '06974718028943',
          inventory_id: '31060101501',
          name: 'A-SAA校准品',
        },
        {
          rfid: '1',
          di: '06974718028950',
          inventory_id: '31060101502',
          name: 'A-SAA质控品',
        }
      ],
      instrumentUdi: [
        {
          rfid: '0210202',
          di: '06974718028660',
          inventory_id: '31010100101',
          name: 'Qpad',
        }
      ],
    }
  },
  computed: {
    scansNum() {
      let temp = parseInt(
        this.cardC.reduce((accumulator, cur) => {
          if (cur.is_udi) {
            return accumulator + parseInt(cur.b)
          } else {
            return accumulator + parseInt(cur.b)
          }
        }, 0)
      )
      return temp;
      // return this.tableData.length - 1 + temp
    },
  },
  watch:  {
    page(newVal,oldVal) {
      if(oldVal == this.total/10){
        this.newtableData = JSON.parse(JSON.stringify(this.tableData)); //将最新页面的数据存储起来
      }
    }
  },
  methods: {
    
    deleteRow(index, rows) {
      let product = rows[index]
      if (product.productName !== '') {
        for (let i = 0; i < this.cardC.length; i++) {
          let cardCItem = this.cardC[i]
          // if (
          //   cardCItem.name === product.productName &&
          //   cardCItem.value === product.batch
          // ) {
          if (
            cardCItem.dispatchs_id === product.dispatchs_id 
          ) {
            cardCItem.b--
            // 根据数量判断单项status
            if (cardCItem.a > cardCItem.b) {
              cardCItem.status = 0
            } else if (cardCItem.a === cardCItem.b) {
              cardCItem.status = 2
            } else {
              cardCItem.status = 1
            }
            break;
          } else if (cardCItem.name === '其他') {
            cardCItem.b--
            if (cardCItem.a == cardCItem.b) cardCItem.status = 2
          }
        }
      }
      rows[index].udi = '';
      rows[index].isDisabled = false;
      rows[index].status = 1;
      rows[index].productName = '';

      // rows.splice(index, 1)
    },
    // 修改扫描的TB
    changeTB(type) {
      // {
        //   rfid: '0210202',
        //   di: '06974718026604',
        //   inventory_id: '31010100101',
        //   name: 'TB-DOT(20)',
        // },
        // {
        //   rfid: '0210402',
        //   di: '06974718026628',
        //   inventory_id: '31010100201',
        //   name: 'TB-DOT婚检(20)（胶体金法）',
        // },

      if(type == 'change'){
        this.Is_TB = !this.Is_TB
        this.UDIList.pop();
      }
      if(this.Is_TB){
        this.UDIList.push({
          rfid: '0210202',
          di: '06974718026604',
          inventory_id: '31010100101',
          name: 'TB-DOT(20)',
        },)
      }else{
        this.UDIList.push({
          rfid: '0210402',
          di: '06974718026604',
          inventory_id: '31010100201',
          name: 'TB-DOT婚检(20)（胶体金法）',
        })
      }
    },
    // 修改发货单状态
    transfer_single_dispatchFun() { 
      if (!isEmptyObject(this.cardB)) {
        Message.error('请填写完整快递信息')
        return
      }
/**
       * 新方法
       */
       this.tableData.forEach(e=>{
        if(e.udi){
          this.oldtableData.push(e)
        }
      })
      let kit_info = [];
      this.oldtableData.forEach((item) => {
        if(item.dispatchs_id){
          kit_info.push({
            dispatchs_id: item.dispatchs_id,
            kit_id: item.udi,
          })
        }

      })

      let params = {
        customer_id: this.cardA.customer_id,
        dispatch_id: this.dispatch_id,
        order_code: this.cardA.title,
        kit_info,
        is_refresh: 1

      }
      const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });

      api.transfer_single_dispatch(params).then(res => {
        if(res.code == 200) {
          this.$alert('已发货', {
            confirmButtonText: '确定',
            callback: action => {
              window.localStorage.removeItem(`tableData${this.dispatch_id}`)
              loading.close();
              this.cancel()
            }
          })
        }
      }).finally(msg => {
        loading.close();
      })
    },
    // 切换页面
    handleCurrentChange(nowPage){
      let num = this.page*10
      if(this.total == num){
        this.tableData = JSON.parse(JSON.stringify(this.newtableData))
      }else{
        this.tableData = this.oldtableData.slice(num-10,num) //浅拷贝可修改
        // this.tableData = JSON.parse(JSON.stringify(this.oldtableData.slice(num-10,num)))
      }
    },
    changeInput(row) {
      
      // let tableDataUdi = this.tableData.map(item=>{
      //   return item.udi;
      // })
      // let setTableDataUdi = [...new Set(tableDataUdi)];
      // if(tableDataUdi.length != setTableDataUdi.length){
      //   Message.error("该UDI码已录入，请更换录入");
      //   // this.tableData[this.tableData.length-1].udi = '';
      //   // this.audio.play();
      //   // return;
      // }

      // 增加一条数据
      // this.tableDataItem.index = ++this.nowTableIndex
      // let item = {...this.tableDataItem}
      // this.tableData.push(item);
      // setTimeout(() => {
      //   document.getElementById('tableItem'+this.nowTableIndex).focus();
      // }, 100);

      // // this.getReagent(row)
      // this.getUDI(row)

      //  增加一条数据
      let tempIndex = row.index
      if(tempIndex<10){
        document.getElementById('tableItem'+(tempIndex+1)).focus();
        this.getUDI(row)
      }else{
        this.getUDI(row)
        
        this.oldtableData = this.oldtableData.concat(this.tableData) //前页数据保存
        window.localStorage.setItem(`tableData${this.dispatch_id}`,JSON.stringify(this.oldtableData));//当前数据保存到缓存里面
        this.tableData = JSON.parse(JSON.stringify(this.temptableData));
        this.total += 10;
        this.page += 1;
        setTimeout(() => {
          document.getElementById('tableItem1').focus();
        }, 100);
      }
    },
    // 高亮
    tableRowClassName({ row, rowIndex }) {
      if (row.status === 0) {
        return 'warning-row'
      } else if (row.status === -1) {
        return 'danger-row'
      }
      return ''
    },
    //确认按钮
    confirmFun() {
      // 校验
      let rerulesA = isEmptyObject(this.cardB)
      if (!isEmptyObject(this.cardB)) {
        Message.error('请填写完整快递信息')
        return
      }
      for (let item of this.cardC) {
        if (item.status != 2) {
          this.toolTip = '发货数量与既定数量不符，是否确认发货'
          this.dialogVisible = true
          return
        }
      }

      this.toolTip = '请确认好快递信息和发货数量，点击确认发货'
      this.dialogVisible = true
    },
    // 订单发货上传UDI码(修改udi码状态)
    create_kitFun() {
      /**
       * 新方法
       */
      this.tableData.forEach(e=>{
        if(e.udi){
          this.oldtableData.push(e)
        }
      })
      let kit_info = [];
      this.oldtableData.forEach((item) => {
        if(item.dispatchs_id){
          kit_info.push({
            dispatchs_id: item.dispatchs_id,
            kit_id: item.udi,
          })
        }
      })
      
      const loading = this.$loading({
        lock: true,
        text: "",
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let num = 0,
          size = 500,
          kit_inf_length = kit_info.length;
      const send = ()=> {
        let params = {
          customer_id: this.cardA.customer_id,
          dispatch_id: this.dispatch_id,
          order_code: this.cardA.title,
        }
        if(kit_inf_length<=(num+1)*size){
          loading.text = `正在上传第${num*size+1}到第${kit_inf_length}个`
          params.kit_info = kit_info.slice(num*size,kit_inf_length)
          api.create_dispatch_kit(params).then(res=> {
            if (res.code == 200) {
              this.$alert('已发货', {
                confirmButtonText: '确定',
                callback: action => {
                  window.localStorage.removeItem(`tableData${this.dispatch_id}`)
                  loading.close();
                  this.cancel()
                }
              })
            }else{
              loading.close();
            }
          }).catch(err=> {
            loading.close();
          })
        }else {
          loading.text = `正在上传第${num*size+1}到第${(num+1)*size}个`
          params.kit_info = kit_info.slice(num*size,(num+1)*size)
          api.create_dispatch_kit(params).then(res=> {
            if(res.code == 200){
              num++;
              send();
            }
          }).catch(err=> {
            loading.close();
          })
        }
      }
      send();

/*       let params = {
        customer_id: this.cardA.customer_id,
        dispatch_id: this.dispatch_id,
        order_code: this.cardA.title,
        kit_info,
      }
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      api.create_dispatch_kit(params).then((res) => {
        if (res.code == 200) {
          this.$alert('已发货', {
            confirmButtonText: '确定',
            callback: action => {
              window.localStorage.removeItem(`tableData${this.dispatch_id}`)
              loading.close();
              this.cancel()
            }
          })
        }else{
          loading.close();
        }
      }).catch(err=> {
        loading.close();
      }) */
    },
    // 修改订单发货状态
    update_dispatch_statusFun(loading) {
      let shipping_status = this.cardB.shipping_type == '6'?'3':'2';
      let params = {
        shipping_status,
        dispatch_id: this.dispatch_id,
      }
      api.update_dispatch_status(params).then((res) => {
        // Message.success('发货成功')
        setTimeout(() => {
          loading.close();
          this.cancel()
        }, 500)
      })
    },
    // 退回上级页面
    cancel() {
      this.$router.go(-1)
    },
    // 将缓存添加到old
    localSetOld() {
      let localtableData = window.localStorage.getItem(`tableData${this.dispatch_id}`)
      if(localtableData){
        this.oldtableData = JSON.parse(localtableData);
        this.total = this.oldtableData.length + 10 
        this.page = this.total/10
        this.handleCurrentChange();
      }
    },
    // 主动缓存
    saveLocal() {
       if(this.page == this.total/10){
        this.newtableData = JSON.parse(JSON.stringify(this.tableData)); //将最新页面的数据存储起来
      }
      window.localStorage.setItem(`tableData${this.dispatch_id}`,JSON.stringify(this.oldtableData));//当前数据保存到缓存里面
      
    },
    // 根据udi码查询接口试剂
    getReagent(row) {
      row.isDisabled = true
      let params = {
        kit_id: row.udi,
      }
      api
        .getkits(params)
        .then((res) => {
          let product = res.data
          row.productName = product.reagent_name
          row.batch = product.batch
          for (let i = 0; i < this.cardC.length; i++) {
            let cardCItem = this.cardC[i]

            if (
              cardCItem.name === product.reagent_name &&
              cardCItem.value === product.batch
            ) {

              row.dispatchs_id = cardCItem.dispatchs_id //赋予单项dispatchs_id
              cardCItem.b++
              // 根据数量判断单项status
              if (cardCItem.a > cardCItem.b) {
                cardCItem.status = 0
              } else if (cardCItem.a === cardCItem.b) {
                cardCItem.status = 2
              } else {
                cardCItem.status = 1
              }
              this.success.play()
              return
            } else if (cardCItem.name === '其他') {
              row.dispatchs_id = cardCItem.dispatchs_id //赋予单项dispatchs_id
              this.audio.play()
              cardCItem.b++
              cardCItem.status = 1
              // tr状态
              row.status = 0
            }
          }
        })
        .catch((err) => {
          this.audio.play()
          row.status = -1
        })
    },
    // 根据UDI自查当前项目
    getUDI(row) {
      row.isDisabled = true
      let kit_id = row.udi
      let temp ;
      if (kit_id.length < 40) {//公司码
        temp = this.UDIList.filter(
          (e) => e.rfid == kit_id.substring(0, 7)
        )[0]
        if(!temp){ // udi无对应的处理
          row.status = -1
          this.audio.play()
          return;
        }
        row.productName = temp.name 
        row.batch = kit_id.substring(7, 15)
   
      } else {//UDI
        temp = this.UDIList.filter(
          (e) => e.di == kit_id.substring(2, 16),
        )[0]
        if(!temp){ // udi无对应的处理
          row.status = -1
          this.audio.play()
          return;
        }
        row.productName = temp.name
        if(kit_id.length === 52){
          row.batch = kit_id.substring(34, 43)
        }else{
          row.batch = kit_id.substring(34, 42)
        }
      }
      for (let i = 0; i < this.cardC.length; i++) {
        let cardCItem = this.cardC[i]
        if (
          cardCItem.inventory_id == temp.inventory_id &&
          cardCItem.value == row.batch
        ) {
          row.dispatchs_id = cardCItem.dispatchs_id //赋予单项dispatchs_id
          cardCItem.b++
          // 根据数量判断单项status
          if (cardCItem.a > cardCItem.b) {
            cardCItem.status = 0
          } else if (cardCItem.a == cardCItem.b) {
            cardCItem.status = 2
          } else {
            cardCItem.status = 1
          }
          this.success.play()
          return
        } else if (cardCItem.name === '其他') {
          row.dispatchs_id = cardCItem.dispatchs_id //赋予单项dispatchs_id
          this.audio.play()
          cardCItem.b++
          cardCItem.status = 1
          // tr状态
          row.status = 0
        }
      }
    },
    // 获取发货订单详情
    order_dispatch_detailsFun() {
      let params = {
        dispatch_id: this.dispatch_id,
      }
      api.order_dispatch_details(params).then((res) => {
        let outstock_codeList = res.data.delivery_code.map(
          (e) => e.outstock_code,
        )
        this.cardA = {
          title: res.data.order_code,
          status: res.data.shipping_status,
          customer_id: res.data.customer_id,
          img: require('@/assets/img/shipment.png'),
          list: [
            {
              name: '发货单号',
              value: res.data.dispatch_code,
            },
            {
              name: '出库单号',
              value: outstock_codeList.join(','),
            },
            {
              name: '经销商',
              value: res.data.customer_name,
            },
            {
              name: '创建时间',
              value: res.data.order_date,
            },
          ],
        }
        const shippingTypeListItem = this.shippingTypeList.find(
          (e) => e.label == res.data.shipping_type,
        )
        let shipping_type = shippingTypeListItem?shippingTypeListItem.value:''
        // let shipping_type = this.shippingTypeList.filter(
        //   (e) => e.label == res.data.shipping_type,
        // )[0].value
        this.cardB = {
          shipping_code: res.data.shipping_code,
          shipping_type,
          receive_man: res.data.receive_man,
          receive_mobile: res.data.receive_mobile,
          receive_address: res.data.receive_address,
          memo: '无',
        }

        this.cardC = res.data.dispatch_info.dispatch_data.map((item) => {
          let a ;
          if (item.format) {
            a = (item.quantity_remain / item.format).toFixed(0)
          } else {
            a = item.quantity_remain
          }
          let is_udi = true;
          if(this.noUdi.indexOf(item.inventory_id) != -1){
            is_udi = false;
          }
          return {
            dispatchs_id: item.dispatchs_id,
            inventory_id: item.inventory_id,
            udiList: [],
            name: item.inventory_name,
            value: item.batch,
            is_udi,
            a,
            b: 0,
            status: 0,
          }
        })
        // 测试使用添加固定条目
        // this.cardC.push({ name: 'O-CRP(100)瓶装单试剂(Ottoman)',
        //     dispatchs_id:'1231',
        //     udiList:[],
        //     value: '20220302',
        //     is_udi: true,
        //     a: 2,
        //     b: 0,
        //     status: 0,});
        this.cardC.push({
          name: '其他',
          dispatchs_id: '',
          inventory_id: '',
          udiList: [],
          value: '',
          is_udi: true,
          a: 0,
          b: 0,
          status: 2,
        })
        // 获取总数
        this.quantity_cnt = 0
        this.cardC.forEach((e) => {
          this.quantity_cnt += Number(e.a)
        })
      })
    },
    // UDI
  },
}
</script>
<style lang="scss" scoped>
.el-table {
  ::v-deep .warning-row {
    background: #e6a23c;
  }
  ::v-deep .danger-row {
    background: #f56c6c;
  }
}

.el-table {
  ::v-deep td {
    padding: px(5);
  }
  ::v-deep .el-input,
  ::v-deep .el-input__inner {
    width: px(500);
    height: px(40);
  }
}

.orderDeliveryDetail {
  .title {
    font-size: px(20);
    font-weight: bold;
    color: #111111;
    margin-bottom: px(20);
  }
  .card {
    background: #fff;
    border-radius: px(5);
    padding: 0 px(14);
    .p1 {
      height: px(58);
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: px(1) solid #ebf0f6;
      box-sizing: border-box;
      span:nth-child(1) {
        font-weight: bold;
      }
      span:nth-child(2) {
        font-size: px(18);
        display: flex;
        justify-content: flex-end;
        align-items: center;
        i {
          display: block;
          height: px(6);
          width: px(6);
          border-radius: 50%;
          background: #999999;
          margin-right: px(8);
        }
        .greenBg {
          background: #54b64a;
        }
      }
      .green {
        color: #54b64a;
      }
    }
    .p2 {
      height: px(58);
      display: flex;
      align-items: center;
      border-bottom: px(1) solid #ebf0f6;
      box-sizing: border-box;
      span:nth-child(even) {
        font-weight: bold;
      }
      span:nth-child(odd) {
        margin-right: px(30);
        ::v-deep .el-input,
        ::v-deep .el-input__inner {
          height: px(40);
        }
      }
    }
    ul {
      display: flex;
      justify-content: space-between;
      padding: px(15) 0;
      li {
        width: 25%;
        p:nth-child(1) {
          color: #999999;
          font-size: px(18);
          margin-bottom: px(5);
        }
        p:nth-child(2) {
          color: #333333;
          font-size: px(20);
          font-weight: bold;
        }
      }
    }
    .u1 {
      display: flex;
      justify-content: start;
      padding: px(5) 0;
      li {
        p:nth-child(1) {
          font-weight: bold;
        }
      }
      li:nth-child(odd) {
        width: 25%;
      }
      li:nth-child(even) {
        width: 70%;
        ::v-deep .el-input,
        ::v-deep .el-input__inner {
          width: px(700);
        }
      }
    }
  }
  .cardA {
    margin-bottom: px(10);
  }
  .titleGoods {
    height: px(51);
    line-height: px(51);
    font-size: px(20);
    color: #2d354c;
    font-weight: bold;
  }
  .goodsContent {
    background: #fff;
    border-radius: px(5);
    padding: px(14) 0 px(20) px(15);
    display: flex;
    justify-content: space-between;
    .boxA {
      display: flex;
      justify-content: flex-start;
      color: #fff;
      p {
        width: px(199);
        height: px(174);
        border-radius: px(4);
        span {
          display: block;
          text-align: center;
        }
        span:nth-child(1) {
          font-size: px(36);
          font-weight: bold;
          margin: px(55) 0 px(10) 0;
        }
        span:nth-child(2) {
          font-size: px(16);
        }
      }
      p:nth-child(1) {
        background: #00afe9;
        margin-right: px(10);
      }
    }
    .rightBox {
      flex: 1;
      padding-left: px(15);
      transition: all 0.3s;
      .list {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        transition: all 0.3s;
        overflow: hidden;
        .boxB {
          width: px(372);
          height: px(175);
          border-radius: px(4);
          border: px(1) solid #ebf0f6;
          box-sizing: border-box;
          margin: 0 0 px(15) px(15);
          position: relative;
          padding: px(15);
          .span1 {
            position: absolute;
            display: block;
            width: px(42);
            height: px(20);
            background: #8591a0;
            border-radius: px(4) 0 px(4) 0;
            left: 0;
            top: 0;
            font-size: px(12);
            color: #ffffff;
            text-align: center;
            line-height: px(20);
          }
          .span2 {
            display: block;
            position: absolute;
            right: 0;
            top: 0;
            width: px(27);
            height: px(20);
            background: #54b64a;
            border-radius: 0 px(4) 0 px(4);
            color: #fff;
            text-align: center;
            line-height: px(20);
            font-size: px(12);
          }
          .titleBox {
            height: px(66);
            border-bottom: px(1) solid #ebf0f6;
            box-sizing: border-box;
            span {
              display: block;
              width: 100%;
              text-align: center;
            }
            span:nth-child(1) {
              color: #333333;
              font-size: px(18);
              font-weight: bold;
              margin-bottom: px(6);
            }
            span:nth-child(2) {
              color: #666666;
              font-size: px(16);
            }
          }
          .twoBox {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: px(15) 0 px(20) 0;
            p {
              width: 48%;
              span {
                width: 100%;
                display: block;
                text-align: center;
              }
              span:nth-child(1) {
                font-size: px(36);
                font-weight: bold;
                color: #333333;
              }
              span:nth-child(2) {
                font-size: px(14);
                color: #666666;
              }
            }
            .line {
              display: block;
              width: px(1);
              height: px(50);
              background: #ebf0f6;
            }
            .inputNum {
              ::v-deep .el-input,
              ::v-deep .el-input__inner {
                height: px(40);
                width: px(150);
              }
            }
          }
        }
      }

      .activeOpen {
        height: px(175);
        transition: all 0.3s;
      }
      .activeClose {
        height: auto;
        transition: all 0.3s;
      }
      .openBtn {
        height: px(52);
        font-size: px(16);
        color: #00afe9;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: px(1) solid #ebf0f6;
        box-sizing: border-box;
        span {
          cursor: pointer;
          transition: all 0.3s;
        }
        .activeIcon {
          transform: rotate(-180deg);
          transition: all 0.3s;
        }
        .activeDow {
          transform: rotate(0deg);
          transition: all 0.3s;
        }
      }
    }
  }
  .el-table {
    padding: 0 px(20);
    margin-bottom: px(60);
  }
}

.bottomBtn {
  position: absolute;
  bottom: 0;
  width: px(1685);
  background: #fff;
  // height: px(56);
  background: #ffffff;
  box-shadow: px(2) 0 px(4) 0 rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 2000;
  .el-button {
    padding: px(10) px(20);
    margin-right: 10px;
  }
  // p {
  //   width: px(78);
  //   height: px(36);
  //   border-radius: px(5);
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   font-size: px(18);
  //   cursor: pointer;
  //   color: #fff;
  // }
  // p:nth-child(1) {
  //   background: #8591a0;
  //   margin-right: px(10);
  // }
  // p:nth-child(2) {
  //   background:#00AFE9 ;
  //   margin-right: px(20);
  // }
}
</style>